import React from "react";
import Title from "../../components/Title";
import WorkItem from "../../components/WorkItem";
import ImgIpet from "../../assets/images/thumbnail/iPet.png";
import ImgGteng from "../../assets/images/thumbnail/gteng.png";
import ImgMayfull from "../../assets/images/thumbnail/mayfull.png";
import ImgFb from "../../assets/images/thumbnail/fb.png";
import Img17life from "../../assets/images/thumbnail/17life.png";
import ImgCJPF from "../../assets/images/thumbnail/CJPF.png";
import ImgIMdownload from "../../assets/images/thumbnail/IMdownload.png";
import ImgLifongpay from "../../assets/images/thumbnail/lifongpay.png";
import ImgMSG from "../../assets/images/thumbnail/MSG.png";
// import ImgStancare from "../../assets/images/thumbnail/stancare.png";
import ImgPxec from "../../assets/images/thumbnail/pxec.png";

const WorksScreen = () => {
  return (
    <div className="container">
      <div className="work-selected row">
        <Title title="Works" descript="作品" />
        <div className="work-wrap">
          <WorkItem
            label="Graphic"
            title="全聯線上購 隔日達"
            descript="-Event pages -Banner -Ads"
            url={ImgPxec}
            alt="全聯線上購 隔日達"
            go="/works/pxec"
          />
          <WorkItem
            label="WEB DEV"
            title="美福大飯店"
            descript="-HTML -CSS -JQuery -RWD"
            url={ImgMayfull}
            alt="美福大飯店"
            go="/works/mayfull"
          />
          <WorkItem
            label="WEB DEV"
            title="富邦產險 保全聯盟鏈"
            descript="-HTML -CSS -JQuery -RWD"
            url={ImgFb}
            alt="富邦產險 保全聯盟鏈"
            go="/works/fb"
          />
          <WorkItem
            label="APP"
            title="17Life生活電商"
            descript="-UI/UX -iOS -Android -WebDev"
            url={Img17life}
            alt="17Life生活電商"
            go="/works/life"
          />
          {/* <WorkItem
            label="APP"
            title="巨安長齡 “StanCare“"
            descript="-UI/UX -iOS -Android"
            url={ImgStancare}
            alt="巨安長齡 “StanCare“"
            go="/works/stancare"
          /> */}
          <WorkItem
            label="APP"
            title="iPet “愛寵GPS寵物追蹤器”"
            descript="-UI/UX -iOS -Android"
            url={ImgIpet}
            alt="iPet “愛寵GPS寵物追蹤器”"
            go="/works/ipet"
          />
          <WorkItem
            label="CIS"
            title="聚天股份有限公司"
            descript="-Branding "
            url={ImgGteng}
            alt="聚天股份有限公司"
            go="/works/gteng"
          />
          <WorkItem
            label="CIS"
            title="利丰支付"
            descript="-Branding "
            url={ImgLifongpay}
            alt="利丰支付"
            go="/works/lifong"
          />
          <WorkItem
            label="PROJECT"
            title="長江跑分"
            descript=" -Branding -UI/UX -React Native"
            url={ImgCJPF}
            alt="長江跑分"
            go="/works/cjpf"
          />
          <WorkItem
            label="WEB DEV"
            title="IM 產品下載頁"
            descript="-HTML -CSS -React -RWD"
            url={ImgIMdownload}
            alt="IM 產品下載頁"
            go="/works/im"
          />
          <WorkItem
            label="PROJECT"
            title="MSG運營總後台"
            descript="-Branding -UI/UX -React"
            url={ImgMSG}
            alt="MSG運營總後台"
            go="/works/msg"
          />
        </div>
      </div>
    </div>
  );
};

export default WorksScreen;
