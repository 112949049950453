import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import WorksScreen from "./screens/WorksScreen";
import CJPFScreen from "./screens/WorksDetailScreen/CJPFScreen";
import FBScreen from "./screens/WorksDetailScreen/FBScreen";
import GtengScreen from "./screens/WorksDetailScreen/GtengScreen";
import IMScreen from "./screens/WorksDetailScreen/IMScreen";
import IPetScreen from "./screens/WorksDetailScreen/IPetScreen";
import LifeScreen from "./screens/WorksDetailScreen/LifeScreen";
import LiFongScreen from "./screens/WorksDetailScreen/LiFongScreen";
import MayfullScreen from "./screens/WorksDetailScreen/MayfullScreen";
import MSGScreen from "./screens/WorksDetailScreen/MSGScreen";
import StancareScreen from "./screens/WorksDetailScreen/StancareScreen";
import PxecScreen from "./screens/WorksDetailScreen/PxecScreen";
import ScrollToTop from "./components/ScrollToTop";
import "./assets/sass/style.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoScreen from "./screens/VideoScreen";
function App() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <BrowserRouter>
      <div className="wrap">
        <Header />
        <div className="main">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/about" component={AboutScreen} />
            <Route exact path="/works" component={WorksScreen} />
            <Route exact path="/works/cjpf" component={CJPFScreen} />
            <Route exact path="/works/fb" component={FBScreen} />
            <Route exact path="/works/gteng" component={GtengScreen} />
            <Route exact path="/works/im" component={IMScreen} />
            <Route exact path="/works/ipet" component={IPetScreen} />
            <Route exact path="/works/life" component={LifeScreen} />
            <Route exact path="/works/lifong" component={LiFongScreen} />
            <Route exact path="/works/mayfull" component={MayfullScreen} />
            <Route exact path="/works/msg" component={MSGScreen} />
            <Route exact path="/works/stancare" component={StancareScreen} />
            <Route exact path="/works/pxec" component={PxecScreen} />
            <Route exact path="/videos" component={VideoScreen} />
            <Redirect from="/*" to="/" />
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
